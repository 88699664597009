.station-select-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5px;
  }
  
  .station-select {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 11px;
    background-color: #fff;
    color: #333;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .station-select:focus {
    border-color: #61dafb;
    box-shadow: 0 0 5px rgba(81, 203, 238, 1);
    outline: none;
  }
  
  .error-message {
    color: red;
    font-size: 14px;
  }
  
  .loading-message {
    font-size: 14px;
    color: #666;
  }
  