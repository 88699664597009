/* Add this to your CSS file or within a <style> tag in your HTML */
.legend_rainfall {
    width: 20px;
    height: 300px;
    background: linear-gradient(to top, #8B0000, #FFC0CB, #FFFFFF, #ADD8E6, #00008B);
    border: 1px solid black;
    position: relative;
  }
  
  .legend-labels {
    position: absolute;
    top: 0;
    left: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }