.MapComponent {
  width: 800px;
  height: 600px; /* Set a fixed height or a height that fits your layout */
  position: relative;
  z-index: 1; /* Ensure the map has a lower z-index */
}

.slider-container {
  margin-top: 20px;
  position: relative;
  z-index: 2; /* Ensure the slider container is above the map */
}

.slider-label {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background: #007bff;
  color: white;
  padding: 5px 10px;
  border-radius: 3px;
  white-space: nowrap;
  z-index: 3; /* Ensure the slider label is above everything else */
  pointer-events: none;
}

.react-input-slider {
  width: 100%;
}

.react-input-slider .slider {
  width: 100%;
  position: relative;
}

.react-input-slider .track {
  width: 100%;
  height: 6px; /* Adjust the height if necessary */
  background: lightgrey;
}

.react-input-slider .active {
  height: 6px; /* Ensure the active track height matches the track height */
  background: #007bff;
}

.react-input-slider .thumb {
  width: 20px; /* Adjust the width of the thumb */
  height: 20px; /* Adjust the height of the thumb */
  background: white;
  border: 2px solid #007bff;
  border-radius: 50%;
  box-shadow: 0 0 2px rgba(0,0,0,0.2);
  cursor: pointer;
}

.legend {
  padding: 10px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  line-height: 18px;
  color: #555;
}

.legend h4 {
  margin: 0 0 5px;
  color: #777;
}

.legend i {
  width: 18px;
  height: 18px;
  float: left;
  margin-right: 8px;
  opacity: 0.7;
}

.legend br {
  clear: both;
}

.EFlowStatusMap {
  position: relative;
}

.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px; /* Smaller size */
  height: 50px; /* Smaller size */
  margin: -25px 0 0 -25px; /* Center the spinner */
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-top: 5px solid #007bff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.date-label {
  background-color: rgba(255, 255, 255, 0.8); /* White background with some transparency */
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Slight shadow to give it a pop effect */
  font-size: 14px;
  font-weight: bold;
  color: #333; /* Dark text color for contrast */
}
.river-code-label {
  font-size: 8px;           /* Smaller font size */
  padding: 2px 2px;          /* Reduce padding for a smaller tooltip */
  background-color: white;   /* Optional: Background color */
  border-radius: 2px;        /* Optional: Rounded corners */
  border: 1px solid #ccc;    /* Optional: Border to make the label stand out */
  color: #333;               /* Text color */
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2); /* Optional: Shadow for better visibility */
}