/* Set a default font size for the entire application */
html {
  font-size: 11px; /* Adjust this value to your preference */
}

body {
  font-family: Helvetica, sans-serif; /* You can set a default font-family as well */
  font-size: 1.3rem; /* Ensure body text scales with the base font size */
  margin: 0;
  padding: 0;
}

.App {
  display: flex;
  height: 100vh;
  width: 100vw;
}

.App-header {
  position: static;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

h1 {
  margin: 20px 0;
}

.ResponsiveContainer {
  width: 100%;
  height: 400px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.input-group {
  margin-bottom: 20px;
}

.select-input,
.date-input,
.submit-button {
  padding: 8px 15px;
  margin-right: 10px;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.select-input {
  cursor: pointer;
}

.date-input {
  cursor: pointer;
}

.submit-button {
  background-color: #28537D;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #28537D;
}

/* Logo styling */
.logo {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 100px; /* Adjust the size of the logo as needed */
  height: auto;
}

.App {
  text-align: center;
}

h1 {
  font-size: 1.5em;
  margin-bottom: 20px;
}

.load-button {
  background-color: #28537D;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 20px 10px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.load-button:hover {
  background-color: #21a1f1;
}

.load-button:focus {
  outline: none;
}

.load-button {
  margin-top: 10px;
}

.MapComponent {
  height: 600px;
  width: 800px;
}

.logo-org {
  height: auto;
}

.iwmi-logo {
  height: 170px;
}

.award-logo {
  height: 10px;
}

/* Base button styles */
.btn-primary {
  background-color: #007BFF; /* Primary blue color */
  color: white; /* Text color */
  border: none; /* No border */
  border-radius: 8px; /* Slightly rounded corners */
  padding: 10px 20px; /* Padding */
  font-size: 16px; /* Font size */
  font-weight: 500; /* Font weight */
  text-transform: uppercase; /* Uppercase text */
  letter-spacing: 1px; /* Slight spacing between letters */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transitions */
}

/* Hover effect */
.btn-primary:hover {
  background-color: #0056b3; /* Darker blue on hover */
  transform: translateY(-2px); /* Slightly lift the button */
}

/* Focus effect */
.btn-primary:focus, .btn-primary.focus {
  outline: none; /* Remove default outline */
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); /* Custom focus shadow */
}

/* Active effect */
.btn-primary:active, .btn-primary.active {
  background-color: #004080; /* Even darker blue on active */
  transform: translateY(0); /* Reset lift effect */
}

/* Disabled effect */
.btn-primary:disabled, .btn-primary.disabled {
  background-color: #b0c4de; /* Light gray-blue for disabled */
  color: #ffffff; /* Lighter text color */
  cursor: not-allowed; /* Show not-allowed cursor */
  box-shadow: none; /* Remove shadow */
}

/* MapComponent.css */
.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  z-index: 1000;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.title-bar {
  text-align: center;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.title {
  font-size: 18px;
  margin: 0;
  padding: 0;
}

.partner-logos {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 5px;
}

.partner-logos img {
  height: 30px;
}

.partner-logos span {
  font-size: 12px;
}

.form-group {
  margin-right: 20px; /* Adjust this margin as needed */
}

.form-control {
  width: auto; /* Allow the input to size naturally */
}

/* New styles for Sidebar */
.cdb-sidebar {
  width: 250px; /* Adjust the width of the sidebar */
}

.main-content {
  width: 100%;
  height: 100vh;
  padding: 15px;
}

