/* Container styling */

.chart-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    position: relative;
}

/* Button container */
.button-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
}

/* Individual button styling */
.button-container button {
    background-color: #28537D; /* IWMI Blue background */
    border: none; /* Remove borders */
    color: white; /* White text */
    padding: 10px 20px; /* Some padding */
    text-align: center; /* Center the text */
    text-decoration: none; /* Remove underline */
    display: inline-block; /* Make the buttons appear side-by-side */
    font-size: 8px; /* Increase font size */
    margin: 4px 2px; /* Some margin */
    cursor: pointer; /* Pointer/hand icon on hover */
    border-radius: 5px; /* Rounded corners */
    transition: background-color 0.3s; /* Smooth background color transition */
}

/* Button hover effect */
.button-container button:hover {
    background-color: #28537D; /* IWMI Blue */
}

/* Y-Axis max input styling */
.y-axis-input-container {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}

.y-axis-input-container label {
    margin-right: 10px;
    font-size: 16px;
    color: #333;
}

.y-axis-input-container input {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
}

/* Chart styling */
.chart {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 10px;
    width: 100%;
    height: 300px;
    background-color: #fff;
}

/* Logo styling */
.logo {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 100px; /* Adjust the size of the logo as needed */
    height: auto;
    z-index: 10;
}
.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30vh; /* or any height you prefer */
    width: 30vh;
}

.spinner {
    animation: spin 2s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.chart-title {
    font-size: 0.4em;
    font-weight: bold;
    text-align: center;
    margin: 2px 0;
}

.channel-id {
    color: #007bff;
}
.button-container button {
    margin: 5px;
    padding: 10px 20px;
    font-size: 8px;
    cursor: pointer;
    border: none;
    background-color: grey;
    color: white;
    transition: background-color 0.3s;
}

.button-container button.active {
    background-color: #007bff;
}
.toggle-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Increased gap */
    margin: 20px 0;
  }
  
  .toggle-switch {
    position: relative;
    display: inline-flex;
    align-items: center;
    width: 30px; 
    height: 16px;
    margin: 5px;
  }
  
  .toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 16px; /* Adjusted to match new height */
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 12px; /* Adjusted to match new height */
    width: 12px; /* Adjusted to match new height */
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:checked + .slider:before {
    transform: translateX(14px); /* Adjusted to match new width */
  }
  
  .label {
    margin-left: 2px; /* Add space between toggle and label */
    font-size: 5px; /* Reduced font size */
  }
  .button-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1px;
    margin: 2px 0;
}

.toggle-button {
    transition: background-color 0.3s;
}

.toggle-button.active {
    background-color: #28a745;
    color: white;
}

  
  
