html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
}
.parent-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column; /* or row, depending on your layout */
}
.chart-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.chart-title {
    font-size: 24px;
    margin-bottom: 10px;
}

.y-axis-input-container {
    margin-bottom: 10px;
}

.button-container {
    margin-bottom: 20px;
}

.logo {
    margin-top: 20px;
    width: 100px;
    height: auto;
}
